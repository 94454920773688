<!--
 * Description: 评论列表和消息列表
 * 评论和消息是一样的格式，但字段不同
-->

<template>
    <div class="list-row" @click="showDetail">
        <div class="list-header" v-if="type=='message'">
            <p class="publish-time">{{info.publishAt}}</p>
            <span class="message-time">{{info.user && info.user.username}}</span>
            <!-- <span class="quick-reply">
                <i class="el-icon-chat-dot-square"></i>
                快速回复
            </span> -->
        </div>
        <div class="list-content">
            <div class="flex" :class="{showAll:expand}" ref="content" v-html="content"></div>
            <span v-if="moreText" @click.stop="triggerTextState">{{textState}}</span>
        </div>
        <div class="list-footer">
            <div class="comment-operation" v-if="type=='comment'">
                <span @click.stop="triggerLike(info)" :class="{blue:count}">
                    <i class="el-icon-s-management"></i>
                    已赞 {{info.liks||info.likes}}
                </span>
                <span @click.stop="triggerCollection(info)" :class="{blue:star}">
                    <i class="el-icon-star-on"></i>
                    {{info.collections}}
                </span>
            </div>
            <div class="info-title">
                {{info.title||info.subjectTitle}}
            </div>
        </div>        
    </div>
</template>

<script>
export default {
    name : "businessList",
    props : {
        type : {
            type : String,
            default : "message"
        },
        info : {
            type : Object,
            default : ()=>{
                return {}
            }
        }
    },
    data(){
        return {
            count : false,
            star : false,
            moreText : true,
            expand : true
        }
    },
    computed : {
        textState(){
            return this.expand ? "收起" : "展开"
        },
        title(){
            let title = this.info.title;
            if( this.keyWord ){
                title = title.replace(new RegExp(this.keyWord,'g'),"<span style='color:#0969fe'>"+this.keyWord+"</span>")
            }
            return title;
        },
        content(){
			
			const {info,type} = this;
			let {user,content} = info;
			
            if( type == "message"){
				let username = user && user.username || ""
				if(username){
					content = `<div><span style='color:#0969fe'>@${username} </span>回复：</div><div style="flex:1">${content}</div>` 
				}
            }
            return content;
        }
    },
    mounted(){
        let height = this.$refs.content && this.$refs.content.offsetHeight
        if( height <= 48 ){
            this.moreText = false;
        }else{
            this.expand = false
        }
    },
    methods : {
        showDetail(){
            this.$emit("showDetail")
        },
        triggerLike(info){
            const {isLike,id} = info;
            const action = isLike ? "remove" : "add";
            let commentList = false;
            if( !id ){
                id = info.subjectId;          //评论用的是这个ID，消息用的ID
                commentList = true;
            }
            info.isLike = !info.isLike
            this.$api.forum.like({
                id,action
            }).then(res=>{
                this.$emit("page")
            })
        },
        triggerCollection(info){
            const {isCollection,id} = info;
            if( !id ) id = info.subjectId;          //评论用的是这个ID，消息用的ID
            const action = isCollection ? "remove" : "add";
            if( isCollection ){
                info.collections --;
            }else{
                info.collections ++;
            }
            info.isCollection = !info.isCollection
            this.$api.forum.collection({
                id,action
            }).then(res=>{
                this.$emit("page")
            })
        },
        triggerTextState(){
            this.expand = !this.expand;
        }
    }
}
</script>

<style lang="scss" scoped>
    .list-row {
		margin-bottom: 24px;
		cursor: pointer;
        .list-header {
            display: flex;
            justify-content: space-between;
            align-items : center;
            margin-bottom: 6px;
            .publish-time {
               font-size: 14px;
               font-family: PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               color: #9AAAB7;
               line-height: 20px;
            }
            span {
                font-size: 14px;
                color: #333333;
				
                &:last-child {
                    font-weight: 600;
                }
            }
        }
        .list-content {
            display: flex;
            position: relative;
            img {
                width: 120px;
                height: 80px;
            }
            > div{
				flex: 1;
                height: 48px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 28px;
                word-break: break-all; 
                overflow: hidden;
                &.showAll {
                    height: auto;
                }
            }
            &::after{
                content: " ";
                display: block;
                clear: both;
            }
            span{
                position: absolute;
                right: 0;
                bottom: 0;
                width: 56px;
                height: 28px;
                background: linear-gradient(90deg, rgba(255,255,255,0.9) 0%, #FFFFFF 100%);
                border-radius: 2px;
                cursor: pointer;
				text-align: center;
				line-height: 28px;
				color: #006AFF;
				font-size: 14px;
				display: inline-block;
            }
        }
        .list-footer {
            .comment-operation {
                text-align: right;
				margin-top: 12px;
                span {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #60707D;
                    margin-left: 16px;
                }
            }
            .info-title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #60707D;
                line-height: 20px;
                background: rgba(247,249,251,0.6);
                border-radius: 16px;
				padding: 7px 16px;
				margin-top: 24px;
            }
            
        }
    }

</style>