
<template>
    <div class="header-tabs-container">
        <div class="header-tabs">
            <span>
                <span 
                    v-for="(tab,index) in tabs" 
                    class="header-tab" 
                    :class="{active:nowTab==index}" 
                    @click="selectTab(index)">
                    <span>{{tab}}</span>
                    <!-- <el-badge v-else is-dot class="item">{{tab}}</el-badge> -->
                </span>
            </span>
            <div class="content-search">
                <slot name="search"></slot>
                <el-button v-if="showButton" type="primary" @click="trigger">发起</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props :{
            tabs : {
                type : Array,
                default : []
            },
            search : {
                type : Function,
                default : ()=>{}
            },
            showButton : {
                type : Boolean,
                default : true
            }
        },
        data(){
        return {
                nowTab : 0,
                searchText : ""
            }
        },
        methods : {
            selectTab(index){
                this.nowTab = index;
                this.$emit("tabClick",index)
            },
            trigger(){
                this.$emit("trigger",this.searchText)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header-tabs-container{
        margin-bottom: 16px;
        .header-tabs {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .header-tab {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #60707D;
                line-height: 28px;
                cursor: pointer;
				margin-right: 24px;
				position: relative;
				padding-bottom: 13px;
				&::after{
					content: '';
					display: block;
					width: 32px;
					height: 4px;
					background: transparent;
					border-radius: 2px;
					margin: 9px auto 0;		
					left: 50%;
					transform: translateX(-50%);
					position: absolute;
					bottom: 0;
				}
                &.active {
					color: #383D41;
					&::after{
						background: #006AFF;						
					}
                }
            }
           .my-header {
                display: inline-block;
                padding-left: calc(50% - 300px);
                span {
                    display: inline-block;
                    padding: 8px 30px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: #CCC;
                    cursor: pointer;
                    &:first-child {
                        border-right: none;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    &:last-child {
                        border-left: none;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                    &.active {
                        color: #5796e3;
                        font-weight: bold;
                        background-color: rgba(87,150,227,0.1);
                        border-color: rgba(87,150,227,0.5);
                        z-index: 100;
                    }
                }
            }
        }
    }
</style>