<template>
  <div class="editor-container">
    <div ref="toolbar" class="editor-toolbar" :hidden="isToolbarHidden" />
    <div ref="editor" class="editor-content" />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    contentType: {
      type: String,
      default: 'html'
    }
  },
  model: {
    prop: 'content',
    event: 'change'
  },
  data() {
    return {
      editor: null,
      isToolbarHidden: true,
      isInit: false,
    }
  },
  methods: {
    initeditor() {
      const that = this
      const E = window.wangEditor
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      // this.editor.config.height = 200;
      this.editor.config.focus = false;
      this.editor.config.pasteIgnoreImg = false
      // this.editor.config.uploadImgServer = '/upload';
      this.editor.config.uploadImgShowBase64 = false;
      this.editor.config.hideLinkImg = false;
      // 菜单自定义有问题,这里采用反选方案
      this.editor.config.excludeMenus = [
        // 'bold',
        // 'fontSize',
        // 'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        // 'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo',
      ]
      this.editor.config.onchange = function (newHtml) {
        if (that.contentType == "text") {
        
          newHtml = that.editor.txt.text()
         
        }
        that.$emit('change', newHtml)
      }
      this.editor.config.onfocus = function (...d) {
        that.isToolbarHidden = false;
      }
      this.editor.config.onblur = function () {
        if (that.isInit) {
          that.isToolbarHidden = true;
          that.$emit('setLastFocusEditor', that.editor);
        }
      }
      this.editor.config.pasteTextHandle = function (pasteStr) {
        // 对粘贴的文本进行处理，
        if (/data:image\/\w+;base64,/g.test(pasteStr)) {
          const target = pasteStr.slice(3, -4);
          return `<p><img width="570" align="middle" src=${target} style="width:300pt" contenteditable="false"></p>`;  //max-width:100%;
        } else {
          return pasteStr
        }
      }
      this.editor.create()
      this.editor.txt.html(this.content)
      this.editor.clear()
      setTimeout(() => {
        document.querySelector("body").click();
        this.isInit = true;
      }, 300);
    }
  },
  mounted() {
    this.initeditor()
  },
  destroyed() {
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style lang="scss" scoped>
.editor {
  &-container {
    position: relative;
    width: 100%;
  }

  &-toolbar {
    width: 100%;
    border: 1px solid #DCDFE6;
    border-bottom: none;
    border-radius: 4px;
  }

  &-content {
    width: 100%;
    height: 124px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
}
</style>