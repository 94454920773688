<template>
    <div class="business-container">
        <div v-if="!detailId" class="business-main">
            <tabHeader :tabs="tabs" :showButton="activeIndex == 0" @tabClick=tabClick @trigger=create>
                <template v-slot:search v-if="activeIndex == 0">
                    <el-input clearable placeholder="请输入内容" :class="{ 'long': searchFocusing }" prefix-icon="el-icon-search"
                        v-model=searchWord @keyup.enter.native=searchBlur @focus=searchFocus>
                    </el-input>
                </template>
            </tabHeader>

            <div class="mb24">
                <div v-if="activeIndex == 0" class="recommend-header">
                    <span :class="{ active: sortBy == 'time' }" @click="sortBy = 'time'">按发布时间</span>
                    <span :class="{ active: sortBy == 'heat' }" @click="sortBy = 'heat'">按热度</span>
                </div>
                <div v-if="activeIndex == 1" class="mine-header">
                    <div class="tab-mine" v-if="activeIndex == 1">
                        <el-radio-group v-model="myTab">
                            <el-radio-button :label=1>主题</el-radio-button>
                            <el-radio-button :label=2>评论</el-radio-button>
                            <el-radio-button :label=3>收藏</el-radio-button>
                            <el-radio-button :label=4>赞</el-radio-button> 
                          
                        </el-radio-group>
                    </div>
                </div>
            </div>

            <div class="list-content" :class="`activeIndex${activeIndex}`">
                <!-- 推荐列表，我的主题列表，我的收藏 -->
                <div v-if="activeIndex == 0 || (activeIndex == 1 && myTab == 1) || (activeIndex == 1 && myTab == 3) || (activeIndex == 1 && myTab == 4)">
                    <div v-if="recommendList.length > 0">
                        <list v-for="(item, index) in recommendList" :key="'list_' + index" :info=item :keyWord="keyWord"
                            :forbidAuths=forbidAuths :listType=myTab :isShowDetail=isShowDetail @refreshCollections=refreshCollections
                            @showDetail="showDetail(item)">
                        </list>
                    </div>
                    <div v-else class="nothing-box" style="margin-top: 100px;">
                        <i class="not-icon"></i>
                        <p class="not-tit">暂无内容</p>
                    </div>
                </div>
                <!-- 评论列表，消息列表 -->
                <div v-if="(activeIndex == 1 && myTab == 2) || activeIndex == 2">
                    <div v-if="recommendList.length > 0">
                        <mes-list v-for="(item, index) in recommendList" :key="'list_' + index" :info=item :type=mesType
                            :keyWord="keyWord" @showDetail="showDetail(item)">
                        </mes-list>
                    </div>
                    <div v-else class="nothing-box" style="margin-top: 100px;">
                        <i class="not-icon"></i>
                        <p class="not-tit">暂无内容</p>
                    </div>
                </div>
                <p class="list-loading" v-if="loading"><i class="el-icon-loading"></i></p>
                <p v-else @click="showMoreClick">{{ bottomText }}</p>
            </div>

            <el-dialog :visible.sync="createVisible" title="发起" width="902px">
                <el-form :model="ruleForm" label-width="60px">
                    <el-form-item label="标题">
                        <el-input placeholder="请输入标题" v-model="createForm.subject" autocomplete="off" maxlength="30"
                            show-word-limit>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="详情">
                        <div style="width: 100%;  padding: 20px;">
                            <quill-editor v-model="createForm.content" :options="editorOption"></quill-editor>
                        </div>

                        <!-- <editor key="create-content" v-model="createForm.content" /> -->
                    </el-form-item>
                </el-form>
                <div class="flex jc_e ai_c pt20">
                    <t-btn @click="createVisible = false" w="78px" h="36px" fs="14px" radius="8px" type="info" pain
                        class="bold mr10">取消</t-btn>
                    <t-btn @click="confirmCommit" w="78px" h="36px" fs="14px" radius="8px" class="bold">提交</t-btn>
                </div>
            </el-dialog>
        </div>
        <!-- <detail v-if="detailId" :id=detailId @back="detailId=null"></detail> -->
    </div>
</template>

<script>
import message from '@/mixins/message'
import tabHeader from "@/components/layout/components/tab-layout.vue"
import list from "./components/business-list.vue"
import MesList from "./components/message-list.vue"
import editor from "@/components/editor";
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'] ,                                        // remove formatting button
    ['image'],
]

export default {
    inject: ["headerRefresh"],
    name: "bussiness",
    components: {
        tabHeader,
        list,
        MesList,
        editor
    },
    mixins: [message],
    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions,  // 工具栏
                    },
                }
            },

            tabs: ["推荐", "我的", "消息",],
            activeIndex: 0,
            sortBy: "time",
            showNumber: 10,
            searchWord: "",
            searchFocusing: false,     //当前搜索框是否聚焦
            loading: false,
            keyWord: "",           //搜索高亮关键字
            recommendList: [],
            page: {                    //分页数据
                page: 1,
                perPage: 10,
                totalCount: 0
            },
            myTab: 1,                 //“我的”内容激活标签页
            createVisible: false,       //发起弹窗控制
            createForm: {
                subject: "",
                content: ""
            },
            ruleForm: {
                title: ""
            },
            rules: {
                title: [
                    { validator: "require", trigger: 'blur' }
                ]
            },
            forbidAuths: [],           //账号禁止内容
            detailId: false       ,//内容详情ID
			isShowDetail:true,//是否显示列表中详情
        }
    },
    mounted() {
        const { info } = this.$route.params;
        if (info && info.id) {
            this.detailId = info.id
        } else {
            this.$api.system.forbidAuth().then(res => {
                this.forbidAuths = res.forbidAuths;
            })
            this.getList();
        }

    },
    watch: {
        sortBy() {
            this.resetList();
            this.getList();
        },
        activeIndex(val) {
            this.resetList();
            this.keyWord = "";
            if (val == 0) {
				this.isShowDetail = true
                this.getList();
            } else if (val == 1) {
				this.isShowDetail = false
                this.getMyList();
                this.myTab = 1;
            } else if (val == 2) {
				this.isShowDetail = true
                this.getMyMessage();
            } 
        },
        myTab(val) {
            this.resetList();
            if (val == 1) {
                this.getMyList();
            } else if (val == 2) {
                this.getMyComments();
            } else if (val == 3) {
                this.getMyCollections();
            } else if (val == 4){
                this.getMylike()
            }
        },
        showNumber(val) {
            this.page.page = this.showNumber / this.page.perPage;
            this.getList();
        },
        detailId(val) {
            if (!val) {
                this.resetList();
                this.getList();
            }
        }
    },
    computed: {
        mesType() {      //是否展示列表
            return this.activeIndex == 2 ? "message" : "comment"
        },
        bottomText() {
            const { page: { totalCount } } = this;
            if (totalCount <= 10) {
                return ""
            } else {
                return this.recommendList.length < totalCount ? "显示更多" : "这里是底线，没有更多了"
            }
        }
    },
    methods: {
        create() {
            if (this.forbidAuths.includes(3)) {
                this.error({
                    message: '该账号禁止发起帖子'
                })
            } else {
                this.createForm.subject = ''
                this.createForm.content = ''
                this.createVisible = true;
            }
        },
        tabClick(index) {
            console.log('111111')
            console.log(index)
            if (this.activeIndex !== index) {
                this.recommendList = [];
                this.activeIndex = index;
                if (index == 1) {
                    this.myTab = 1;
                }
            }
        },
        showMoreClick() {
            if (this.showNumber < this.page.totalCount) {
                this.showNumber += 10;
            }
        },
        searchFocus() {
            this.searchFocusing = true;
        },
        searchBlur() {
            this.keyWord = this.searchWord;
            this.searchFocusing = false;
            this.resetList();
            this.search();
        },
        search() {
            const { page, sortBy, keyWord: keyword } = this
            if (keyword.length == 0) {
                this.getList();
            } else {
                this.loading = true;
                this.$api.forum.search({
                    ...page, keyword, sortBy
                }).then(data => {
                    this.loading = false;
                    if (page.page == 1) {
                        this.recommendList = data.items;
                    } else {
                        this.recommendList = this.recommendList.concat(data.items);
                    }
                    // this.keyWord = this.searchWord = data.keyword
                    this.page = data.page;
                }).catch(err => {
                    this.loading = false;
                })
            }
        },
        confirmCommit() {

            const { createForm } = this;

            if (createForm.content.trim().length == 0) {
                this.$store.commit('SETMSG', {
                    title: "系统提示",
                    type: 'error',
                    message: '内容不能为空'
                })
                return
            }


            if (createForm.subject.trim().length == 0) {
                this.$store.commit('SETMSG', {
                    title: "系统提示",
                    type: 'error',
                    message: '内容不能为空'
                })
                return

            }

            this.$api.forum.create({
                ...createForm
            }).then(data => {
                this.success({
                    width: "439px",
                    type: "success",
                    message: '发起话题成功!',
                    duration: 1000
                })
                this.resetList();
                this.getList();
                this.createVisible = false;
            })
        },

        resetList() {
            this.recommendList = [];
            this.page = {
                page: 1,
                perPage: 10,
                totalCount: 0
            }
            this.showNumber = 10;
        },

        refreshCollections() {
            this.resetList();
            this.getMyCollections();
        },

        /**
         * @description 刷新当前列表
         * @return void
         */
        refreshList() {
            const { myTab, activeIndex } = this;
            if (activeIndex == 1) {

            }
        },

        /**
         * @description 获取推荐列表
         * @return void
         */
        getList() {
            const { page, sortBy, keyWord: keyword } = this
            this.loading = true;
            this.$api.forum.list({
                ...page, keyword, sortBy
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                // this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

        /**
         * @description 获取我的话题列表
         * @return void
         */
        getMyList() {
            const { page, sortBy, keyWord: keyword } = this
            this.loading = true;
            this.$api.forum.mysubject({
                ...page, keyword, sortBy
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                // this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

        /**
         * @description 获取我的评论列表
         * @return void
         */
        getMyComments() {
            const { page } = this
            this.loading = true;
            this.$api.forum.mycomments({
                ...page
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

        /**
         * @description 获取我的收藏
         * @return void
         */
        getMyCollections() {
            const { page, sortBy, keyWord: keyword } = this
            this.loading = true;
            this.$api.forum.mycollections({
                ...page, sortBy
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

        /**
         * @description 获取我的消息列表 messageComments
         * @return void
         */
        getMyMessage() {
            const { page } = this
            this.loading = true;
            this.$api.forum.messageNews({
                ...page
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

         /**
         * @description 获取我的赞列表 mylike
         * @return void
         */
         getMylike() {
            const { page } = this
            this.loading = true;
            this.$api.forum.mylike({
                ...page
            }).then(data => {
                this.loading = false;
                if (page.page == 1) {
                    this.recommendList = data.items;
                } else {
                    this.recommendList = this.recommendList.concat(data.items);
                }
                this.keyWord = this.searchWord = data.keyword
                this.page = data.page;
            })
        },

        /**
         * @description 显示文章详情
         * @return void
         */
        showDetail({ id, isAudited }) {
            if (isAudited == false && typeof isAudited == "boolean") {
                this.error({
                    message: '内容尚未审核!',
                    type: "error",
                    width: "439px"
                })
            } else {
                // this.detailId = id;
                this.$router.push({
                    name: 'forumDetail',
                    params: {
                        id
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
/* 搜索框动画 */
@-webkit-keyframes focus {
    from {
        width: 300px;
    }

    to {
        width: 500px;
    }
}

@keyframes focus {
    from {
        width: 300px;
    }

    to {
        width: 500px;
    }
}

@-webkit-keyframes blur {
    from {
        width: 500px;
    }

    to {
        width: 300px;
    }
}

@keyframes blur {
    from {
        width: 500px;
    }

    to {
        width: 300px;
    }
}


.business-container {
    height: 100%;
    background: #fff;
    padding: 24px;
    border-radius: 16px;

    .business-main {
        position: relative;
        height: 100%;

        .recommend-header {
            background: #fff;

            span {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
                color: #9AAAB7;
                min-width: 78px;
                height: 32px;
                line-height: 32px;
                background: rgba(195, 207, 217, 0.2);
                border-radius: 8px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;

                +span {
                    margin-left: 6px;
                }

                &.active {
                    background: rgba(5, 107, 253, 0.1);
                    color: #056BFD;
                }
            }
        }

        .list-content {
            width: 100%;
            height: calc(100% - 52px);
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0;
            }

            &.activeIndex0 {
                height: calc(100% - 113px);
            }

            .list-loading {
                text-align: center;
                font-size: 30px;
            }

            p {
                text-align: center;
                cursor: pointer;
            }
        }

        /deep/ .content-search {
            text-align: right;

            .el-button {
                width: 78px;
                height: 36px;
                font-size: 14px;
                border-radius: 8px;
                padding: 0;
            }

            .el-input {
                width: 294px;
                height: 40px;
                animation-name: blur;
                animation-duration: 0.5s;
                margin-right: 24px;

                input {
                    background: rgba(195, 207, 217, 0.1) !important;
                    border-radius: 20px;
                    border-color: #f8fafb;
                    padding: 0 10px 0 34px;
                    font-size: 12px;
                }

                &.long {
                    width: 439px;
                    animation-name: focus;
                    animation-duration: 0.5s;
                }
            }
        }

        .mine-header {
            position: absolute;
            right: 0;
            top: 0;

            .tab-mine {
                text-align: center;

                /deep/.el-radio-button__orig-radio+.el-radio-button__inner {
                    padding: 6px 21px;
                    font-size: 14px;
                    line-height: 20px;
                }

                /deep/.el-radio-button:first-child .el-radio-button__inner {
                    border-radius: 8px 0 0 8px;
                }

                /deep/.el-radio-button:last-child .el-radio-button__inner {
                    border-radius: 0 8px 8px 0;
                }

                /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
                    color: #006AFF;
                    font-weight: bold;
                    background-color: rgba(5, 107, 253, 0.10);
                    border: 1px solid #006AFF;
                }
            }
        }

        /deep/ .el-form-item__label {
            padding: 0 24px 0 0;
        }

        /deep/ .editor-container {
            border-radius: 4px;
            border: 1px solid #D0D0D0;
            overflow: hidden;

            .editor-toolbar {
                border-radius: 0;
                border: none;
            }

            .editor-content {
                border-radius: 0;
                border: none;
                height: 260px;
                font-size: 14px;
                color: #60707D;
            }
        }
    }
}
</style>