<!--
 * Description: 详情页 评论组件（投诉，点赞，收藏也放在这里）
-->

<template>
    <div class="comment-contaier">
        <div class="detail-operation">
            <span @click="triggerCollection(data)" :class="{yellow:data.isCollection}">
                <i class="icon icon-collection" :class="{yellow:data.isCollection}"></i>
                {{data.collections}}
            </span>
            <span @click="triggerLike(data)" :class="{blue:data.isLikes||data.isLike}">
                <i class="icon icon-like" :class="{blue:data.isLikes||data.isLike}"></i>
                {{data.likes}}
            </span>
            <span v-if="reply" :class="{'discussing':discussing}" @click="discussing=!discussing">
                <i class="icon icon-comment" v-show="!discussing"></i>
                {{commentText}}
            </span>
            <span v-if="detailReply" @click="discussing=true">
                <i class="icon icon-comment"></i>
                {{data.comments}}
            </span>
            <span v-if="showDelete || (delOperation&&data.user.userId==currentId)" @click="deleteComment(data.id)">
                <i class="icon icon-delete"></i>
				删除
            </span>			
			<!-- <span v-if="needComplain" @click="complain">
			    <i class="icon icon-complain"></i>
			    投诉
			</span> -->
        </div>
        <div class="detail-comment" v-if="discussing">
            <div class="flex ai_s">
				<span class="label">回复：</span>
                <el-input
                    type="textarea"
                    :rows="6"
                    resize="none"
                    placeholder="请输入回复内容"
                    v-model="comment">
                </el-input>
            </div>
            <div class="flex jc_e mt16">
				<t-btn
					v-if="reply && discussing"
				    @click="discussing=false"
				    w="78px"
				    h="36px"
					fs="12px"
					type="info mr16"
				    class="pointer bold">取消回复</t-btn>
				<t-btn
					v-if="detailReply && discussing"
				    @click="discussing=false"
				    w="78px"
				    h="36px"
					fs="12px"
					type="info mr16"
				    class="pointer bold">取消回复</t-btn>
				<t-btn
					@click="commit"
					w="78px"
					h="36px"
					fs="12px"
					class="pointer bold">提交</t-btn>
            </div>
        </div>
        <slot v-else></slot>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name : "detail-comment",
    props : {
        id : {
            type : String,
            default : ""
        },
        data : {
            type : Object,
            default : ()=>{
                return {
                    liks : "",
                    collections : "",
                    comments : ""
                }
            }
        },
        delOperation : {
            type : Boolean,
            default : false
        },
        currentId : {
            type : [String,Number],
            default : ""
        },
        showDelete : {
            type : Boolean,
            default : false
        },
        reply : {
            type : Boolean,
            default : true
        },
        detailReply : {
            type : Boolean,
            default : false
        },
        needComplain : {
            type : Boolean,
            default : true
        },
    },
    data(){
        return {
            discussing : false,     //是否处于编写评论
            comment : "",           //评论内容
            count:"",
            star:"",
            detail : {

            }
        }
    },
    computed: {
        commentText(){
            return this.discussing ? "取消回复" : this.data.comments
        }
    },
    methods: {
        ...mapMutations(['SETMSGDIALOG']),
        triggerLike(info){
            const id = this.id;
            const {isLike} = this.data;
            const action = isLike ? "remove" : "add";
            let params = {action};
            if( id ){
                params.id = id;
                params.commentId = info.id;
            }else{
                params.id = info.id;
            }
            
            info.isLike = !info.isLike
            if( isLike ){
                info.likes --;
            }else{
                info.likes ++;
            }
            this.$api.forum.like(params).then(res=>{
                this.$emit("page")
            })
        },
        triggerCollection(info){
            const id = this.id;
            const {isCollection} = this.data;
            const action = isCollection ? "remove" : "add";
            let params = {action};
            if( id ){
                params.id = id;
                params.commentId = info.id;
            }else{
                params.id = info.id;
            }
            
            info.isCollection = !info.isCollection
            if( isCollection ){
                info.collections --;
            }else{
                info.collections ++;
            }
            this.$api.forum.collection(params).then(res=>{
                this.$emit("page")
            })
        },
        deleteComment(id){
            if( this.data.currentId ){
                this.SETMSGDIALOG({
                    title: '系统提示',
                    message: '确定删除该该话题吗？',
					type:"error",
					width:"439px",
                    commit: () => {
                        this.$api.forum.delete({id}).then(res=>{
                            this.SETMSGDIALOG(null)
                            this.$emit("backList")
                        })
                    }
                })
            }else{
                this.SETMSGDIALOG({
                    title: '系统提示',
                    message: '确定删除该该评论吗？',
					type:"error",
					width:"439px",
                    commit: () => {
                        this.$api.forum.comment({
                            id : this.id,
                            commentId:id,
                            action : "remove"
                        }).then(res=>{
                            this.SETMSGDIALOG(null)
                            this.$emit("refresh",true)
                        })
                    }
                })
            }
        },
        commit(){
            const {id} = this.data;
            const {comment} = this;
            this.discussing = false;
            this.$emit("commitComment",{
                id,comment
            })
            this.comment = "";
        },
        complain(){
            this.$emit("complain")
        }
    }

}
</script>

<style lang="scss" scoped>
    .comment-contaier {
        .detail-operation {
            display: flex;
            align-items: center;
			justify-content: flex-start;
            padding-top: 12px;
            span {
               display: flex;
                align-items: center;
                cursor: pointer;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #60707D;
				+span{					
					margin-left: 16px;
				}
                &.discussing {
                    color: #056BFD;
                }
				&.blue {
					color: #0969fe
				}
				&.yellow{
					color: #F4A93D;						
				}
            }
            .icon {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 2px;
                &.icon-like {
                    background: url('../../../assets/image/forum/icon_dianzan_off.png') no-repeat;
                    background-size: 100%;
                    &.blue {
                        background: url('../../../assets/image/forum/icon_dianzan_on.png') no-repeat;
                        background-size: 100%;
                    }
                }
                &.icon-collection {
                    background: url('../../../assets/image/forum/icon_collect_off.png') no-repeat;
                    background-size: 100%;
                    &.yellow {
                        background: url('../../../assets/image/forum/icon_collect_on.png') no-repeat;
                        background-size: 100%;
                    }
                }
				&.icon-delete{
					background: url('../../../assets/image/forum/icon_delete_off.png') no-repeat;
					background-size: 100%;
				}
				&.icon-complain{
					background: url('../../../assets/image/forum/icon_complain_off.png') no-repeat;
					background-size: 100%;
				}
                &.icon-comment {
                    background: url('../../../assets/image/forum/icon_comment_off.png') no-repeat;
                    background-size: 100%;
                }
            }
            .detail-replay-text {
                color: #006AFF;
            }
        }
        .detail-comment {
            margin-top: 12px;
			background: #F9F9F9;
			border-radius: 8px;
			padding: 12px 14px;
			.label{
				width: 40px;
				font-size: 12px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #60707D;
				line-height: 17px;
			}
			/deep/.el-textarea{
				.el-textarea__inner{
					height: 64px;
				}
			}
        }
    }
</style>